html,
body {
  font-family: "Poppins", "Courier New", Courier, monospace;
}

.navbar {
  display: flex;
  padding-top: 18px;
  padding-bottom: 10px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.navbar .inner-navbar {
  max-width: 1380px;
  margin: 0 auto;
}

.navbar img {
  max-width: 70px;
}

.hero {
  padding: 10px 5%;
  display: flex;
  flex-direction: column;
  max-width: 1380px;
  margin: 0 auto;
}

.hero--image {
  width: 60%;
  align-self: center;
  display: flex;
  padding: 30px 0px;
  max-width: 800px;
}

.hero p {
  margin: 0;
}

section {
  padding: 20px;
}

.cards-list {
  max-width: 1380px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 20px;
  overflow-x: auto;
  margin: 0 auto;
  margin-bottom: 4rem;
}

.card {
  max-width: 200px;
  font-size: 12px;
  position: relative;
  flex: 0 0 auto; /* ADD */
  display: flex; /* ADD */
  flex-direction: column; /* ADD */
}

.card span,
.card p {
  font-size: 0.8rem;
  margin: 0;
  padding: 2px 0px;
}

.gray {
  color: #918e9b;
}

.card--image {
  width: 100%;
  border-radius: 10px;
  height: 265px;
}

.rating {
  display: flex;
  align-items: center;
}

.star {
  height: 14px;
}

.card--title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.sold-out,
.open-spots {
  color: #918e9b;
  font-size: 0.8rem;
  font-weight: 900;
  position: absolute;
  background-color: #fff;
  text-transform: uppercase;
  margin: 5px !important;
  border-radius: 3px;
  padding: 2px 5px !important;
}

.open-spots {
  top: 0;
  right: 0;
}

@media screen and (max-width: 540px) {
  .hero--image {
    width: 80%;
    padding: 20px 0px;
  }
  .card--image {
    width: 100%;
    border-radius: 10px;
    height: 235px;
  }
}
